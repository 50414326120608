import React from "react"
import { graphql } from "gatsby"
import { GrAlert } from "react-icons/gr"
import "./alert.module.scss"
interface AlertProps {
  backgroundColor?: string
  date?: string
  iconCodeName?: string
  url: string
  title: string
}

export const AlertBanner = ({
  backgroundColor = "",
  date,
  iconCodeName = "",
  url,
  title,
}: AlertProps) => {
  const getIcon = (codename: string) => {
    switch (codename) {
      case "alert":
        return <GrAlert />
      default:
        break
    }
  }

  return (
    <div className="page-content-above-parallax-bg-image">
      <div className="emergency-ticker" style={{ background: backgroundColor }}>
        <div className="marquee">
          {iconCodeName && getIcon(iconCodeName)}
          <a href={url}>
            <strong>{date ? ` ${date}: ` : ""}</strong>
            {title}
          </a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment AlertBannerFragment on kontent_item_alert_banner {
    id
    elements {
      alert_text {
        value
      }
      background_color {
        value
      }
      date {
        value(formatString: "M/D/YY")
      }
      icon {
        value {
          codename
        }
      }
      link {
        value
      }
    }
  }
`
