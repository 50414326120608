import React, { MouseEventHandler } from 'react';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io'

type SlickArrow = {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export function SampleNextArrow(props: SlickArrow) {
  const { onClick } = props;
  return (
    <button
      type="button"
      data-role="none"
      className="slick-arrow fr-tabbed-hero-content-slider__slick-arrow fr-tabbed-hero-content-slider__slick-arrow--next"
      onClick={onClick}
      aria-label='next-button'
    >
      <IoMdArrowDropright size={45} />
    </button>
  );
}

export function SamplePrevArrow(props: SlickArrow) {
  const { onClick } = props;
  return (
    <button
      type="button"
      data-role="none"
      className="slick-arrow fr-tabbed-hero-content-slider__slick-arrow fr-tabbed-hero-content-slider__slick-arrow--prev"
      onClick={onClick}
      aria-label='previous-button'
    >
      <IoMdArrowDropleft size={45} />
    </button>
  );
}
