import React from "react"
import { graphql } from "gatsby"
import { HeroContent } from "./HeroContent"
import { useColorAreaSettings } from "../../hooks/useColorAreaSettings"
import { ParallaxBackgroundImage } from "./ParallaxBackgroundImage"
import type { Banner } from "./HeroBanner-types"
import "./hero.module.scss"

interface HeroProps {
  banner: Banner
}

export const HeroBanner = ({ banner }: HeroProps) => {
  const { background_image, heading, sub_heading, buttons, secondary_image } =
    banner
  const { imageBannerColorOverlay } = useColorAreaSettings()

  const bannerColorOverride = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundColor`
    : ``

  const contentClassName =
    secondary_image && secondary_image?.value?.length > 0
      ? "fr-hero__banner-content-with-image"
      : "fr-hero__banner-content"

  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  const backgroundImageUrl = background_image?.value[0]?.url,
    hasBackgroundImage = !!backgroundImageUrl

  return (
    <section className={`fr-hero ${bannerColorOverride} ${overrideBackground}`}>
      {hasBackgroundImage && (
        <ParallaxBackgroundImage image={background_image?.value[0]} />
      )}

      <div className={`${contentClassName} fr-container`}>
        <HeroContent
          heading={heading?.value}
          subHeading={sub_heading}
          buttons={buttons?.value}
          secondaryImage={secondary_image?.value[0]}
          baseClassName={contentClassName}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HeroBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      tagline {
        value
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components {
        modular_content {
          ...FormFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
