import React, { useCallback, useMemo } from "react"
import { graphql } from "gatsby"
import { HeroContent } from "../HeroContent"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"
import { HeroTab } from "../hero-tab"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import type { CmsAssetImage } from "_types/AssetsImage"
import "./tabbed-hero-banner.module.scss"

interface TabbedHeroBannerProps {
  banner: {
    background_image?: CmsAssetImage
    secondary_image?: CmsAssetImage
    hero_tabs: {
      modular_content: HeroTab[]
    }
  }
}

export const TabbedHeroBanner = ({ banner }: TabbedHeroBannerProps) => {
  const { background_image, secondary_image, hero_tabs } = banner

  const hasAtleastOneSecondaryImgInTab = useMemo(
    () =>
      hero_tabs?.modular_content?.some(
        ({ elements }) => !!elements?.secondary_image_override?.value[0]
      ),
    [hero_tabs]
  )

  const contentClassName =
    (secondary_image && secondary_image?.value?.length > 0) ||
    hasAtleastOneSecondaryImgInTab
      ? "fr-hero__banner-content-with-image"
      : "fr-hero__banner-content"

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const { heading, sub_heading, buttons, secondary_image_override } =
        activeTabContent?.elements
      return (
        <HeroContent
          heading={heading?.value}
          subHeading={sub_heading}
          buttons={buttons?.value}
          secondaryImage={
            secondary_image_override?.value[0] || secondary_image?.value[0]
          }
          baseClassName={contentClassName}
        />
      )
    },
    [secondary_image, contentClassName]
  )

  const handleGetHeroBgImage = useCallback(
    (activeTabContent?: HeroTab) => {
      if (!activeTabContent) return

      const backgroundImageOverride =
        activeTabContent?.elements?.background_image_override?.value[0]

      const defaultBackgroundImage = background_image?.value[0]

      const heroBackgroundImage =
        backgroundImageOverride || defaultBackgroundImage

      return heroBackgroundImage
    },
    [background_image]
  )

  return (
    <TabbedContainer allContent={hero_tabs.modular_content}>
      <TabbedHeroBannerContent
        contentClassName={contentClassName}
        handleGetHeroBgImage={handleGetHeroBgImage}
        handleActiveTabContent={getActiveTabContent}
      />
    </TabbedContainer>
  )
}

export const tabbedHeroFragment = graphql`
  fragment TabbedHeroBannerFragment on kontent_item_tabbed_hero {
    id
    elements {
      hero_tabs {
        modular_content {
          ...HeroTabFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`

export const heroTabFragment = graphql`
  fragment HeroTabFragment on kontent_item_hero_tab {
    id
    elements {
      tab_title {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      tagline {
        value
      }
      background_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
