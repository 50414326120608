import React, { useEffect, useState } from "react"
import "./form-stub.module.scss"
import { KontentItemFormFieldElements } from "../forms/formBuilder-types"
import { FormStubBannerButtonValue } from "./formStubBanner-types"
import { AnchorLink } from "components/anchor-link/AnchorLink"
import { useLayout } from "contexts/components/LayoutContext"

interface FormStubProps {
  button: FormStubBannerButtonValue
  field: KontentItemFormFieldElements
}

export const FormStub = ({ button }: FormStubProps) => {
  const { button_text, anchor_reference, custom_anchor_reference, linked_page } = button
  const anchorSlug =
      custom_anchor_reference?.value ||
      anchor_reference?.value[0]?.name ||
      "form-banner"
  const { slug, breadcrumbLocation } = useLayout()

  const [refExists, setRefExists] = useState(null)

  useEffect(() => {
    const ref = document.getElementById(anchor_reference.value[0].name) !== null
    setRefExists(ref);
  }, []);

  const path = slug == "/" ? "" : slug
  let linkedPageSlug
  if (linked_page && linked_page?.value.length > 0) {
    linkedPageSlug = linked_page?.value[0].elements.slug.value
  }

  if (linkedPageSlug) {
    return (
      <div className="form-stub">
        <AnchorLink
          children={button_text.value}
          to={`/${linkedPageSlug}#${anchorSlug}`}
          className="button--solid-accent form-stub__button"
        />
      </div>
    )
  } else if (refExists) {
    return (
      <div className="form-stub">
        <AnchorLink
          children={button_text.value}
          to={`${breadcrumbLocation}#${anchor_reference?.value[0]?.name ? anchor_reference?.value[0]?.name : 'form-banner'}`}
          className="button--solid-accent form-stub__button"
        />
      </div>
    )
  } else {
    return (
      <div className="form-stub">
        <AnchorLink
          children={button_text.value}
          to={`/contact-us#${anchor_reference?.value[0]?.name ? anchor_reference?.value[0]?.name : 'form-banner'}`}
          className="button--solid-accent form-stub__button"
        />
      </div>
    )
  }

}
