import React from "react"
import { AnchorNumber } from "./AnchorNumber"
import { useGeneralSettings } from "../../hooks/useGeneralSettings"
import { FormStub } from "./FormStub"
import { graphql } from "gatsby"
import { KontentFormStubBannerFormatted } from "./formStubBanner-types"
import "./form-stub.module.scss"

interface StubProps {
  formStub: KontentFormStubBannerFormatted
  nextBlockHasHeading?: boolean
}

export const StubBanner = ({ formStub, nextBlockHasHeading }: StubProps) => {
  const { heading, button, fields } = formStub
  const marginBottomModifier = nextBlockHasHeading
  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
    usePhoneNumberNotTrackingNumber,
  } = useGeneralSettings()

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Or Call Today! "

  const PhoneLink = () => {
    const { trackingNumber, phoneNumber, id } = trackingNumbers[0]
    if (usePhoneNumberNotTrackingNumber && !phoneNumber) return <></>
    if (!usePhoneNumberNotTrackingNumber && !trackingNumber) return <></>
    return (
      <AnchorNumber
        key={id}
        phoneNumber={phoneNumber}
        trackingNumber={trackingNumber}
        showIcon={false}
        label={trackingNumberCTAText}
      />
    )
  }

  return (
    <section className={`form-stub-banner ${marginBottomModifier}`}>
      <div className="fr-container fr-container--large">
        <div className="form-stub-banner-container">
          <h2 className="form-stub-banner__heading">{heading}</h2>
          <FormStub field={fields} button={button} />
          {trackingNumbers.length > 0 && <PhoneLink />}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FormStubBannerFragment on kontent_item_form_stub_banner {
    id
    elements {
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      heading {
        value
      }
      subheading {
        value
      }
      fields {
        value {
          ...FormFieldFragment
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
