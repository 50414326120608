import React from "react"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { HeroTab } from "../hero-tab"
import { Tabs } from "components/tabs/Tabs"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { TabbedHeroContentSlider } from "./TabbedHeroContentSlider"
import { ImageBuilder } from "_types/AssetsImage"
import { ParallaxBackgroundImage } from "../ParallaxBackgroundImage"

interface TabbedHeroBannerContentProps {
  contentClassName: string
  handleGetHeroBgImage: (activeTabContent?: HeroTab) => ImageBuilder | undefined
  handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  handleGetHeroBgImage,
  handleActiveTabContent,
}: TabbedHeroBannerContentProps) => {
  const { isMobile } = useWindowDimensions()
  const { activeItem, allContent } = useTabbedContainer<HeroTab>()
  const { imageBannerColorOverlay } = useColorAreaSettings()
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  const ariaLabelledBy = `tab-${activeItem.elements?.tab_title?.value.replaceAll(
    " ",
    "_"
  )}-${activeItem.id}`

  const tabPanelId = `tabpanel-${activeItem.elements?.tab_title?.value.replaceAll(
    " ",
    "_"
  )}-${activeItem.id}`

  const activeBgImg = handleGetHeroBgImage(activeItem)

  return (
    <section className={`fr-hero ${overrideBackground}`}>
      {activeBgImg && (
        <ParallaxBackgroundImage image={activeBgImg} loading="eager" />
      )}

      <div
        className={`${contentClassName} ${contentClassName}--tabbed-version fr-container fr-container--large`}
      >
        {!isMobile && (
          <>
            <Tabs className="fr-hero-tabs-controller" />
            {typeof window !== "undefined" && activeItem && (
              <div
                role="tabpanel"
                aria-labelledby={ariaLabelledBy}
                id={tabPanelId}
              >
                {handleActiveTabContent(activeItem)}
              </div>
            )}
            {typeof window === "undefined" && (
              <div
                role="tabpanel"
                aria-labelledby={`tab-${allContent[0].elements?.tab_title?.value.replaceAll(
                  " ",
                  "_"
                )}-${allContent[0].id}`}
                id={`tabpanel-${allContent[0].elements?.tab_title?.value.replaceAll(
                  " ",
                  "_"
                )}-${allContent[0].id}`}
              >
                {handleActiveTabContent(allContent[0])}
              </div>
            )}
          </>
        )}
        {isMobile && <TabbedHeroContentSlider />}
      </div>
    </section>
  )
}
